import React, { useState, useEffect } from 'react';
import api from '../utils/api';
import { Link } from 'react-router-dom';


function Stores() {
  const [stores, setStores] = useState([]);
  const [owners, setOwners] = useState([]);
  const [newStore, setNewStore] = useState({
    name: '',
    address: '',
    phone: '',
    owner_id: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([fetchStores(), fetchOwners()]);
        setError(null);
      } catch (error) {
        console.error('Error fetching stores:', error); // Debug log

        setError('An error occurred while fetching data');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const fetchStores = async () => {
    try {
      const response = await api.get('/stores');
      console.log('API Response:', response.data); // Debug log

      setStores(response.data);
    } catch (error) {
      console.error('Error fetching stores:', error);
    }
  };

  const fetchOwners = async () => {
    try {
      const response = await api.get('/owners'); // Adjust this endpoint as needed
      setOwners(response.data);
    } catch (error) {
      console.error('Error fetching owners:', error);
    }
  };

  const handleInputChange = (e) => {
    setNewStore({ ...newStore, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/stores', newStore);
      setStores([...stores, response.data]);
      setNewStore({ name: '', address: '', phone: '', owner_id: '' }); // Reset form
    } catch (error) {
      console.error('Error creating store:', error);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h2>Stores</h2>
      
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          value={newStore.name}
          onChange={handleInputChange}
          placeholder="Store Name"
          required
        />
        <input
          type="text"
          name="address"
          value={newStore.address}
          onChange={handleInputChange}
          placeholder="Address"
          required
        />
        <input
          type="tel"
          name="phone"
          value={newStore.phone}
          onChange={handleInputChange}
          placeholder="Phone Number"
          required
        />
        <select
          name="owner_id"
          value={newStore.owner_id}
          onChange={handleInputChange}
          required
        >
          <option value="">Select an owner</option>
          {owners.map((owner) => (
            <option key={owner.id} value={owner.id}>
              {owner.name} {/* Adjust this based on your owner object structure */}
            </option>
          ))}
        </select>
        <button type="submit">Add Store</button>
      </form>

      <ul>
      <div className="stores-container">
      <h2>Our Stores</h2>
      {isLoading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : stores.length > 1 ? (
        <div className="stores-grid">
          {stores.map((store) => (
            <Link 
              to={`/stores/${store.id}`} 
              key={store.id} 
              className="store-card"
            >
              <h3>{store.name}</h3>
            </Link>
          ))}
        </div>
      ) : (
        <p>No stores available</p>
      )}
    </div>
      </ul>
    </div>
  );
}

export default Stores;