import React, { useState } from 'react';
import Profile from './components/Profile';
import Redemptions from './components/Redemptions';
import Stores from './components/Stores'
import LandingPage from './components/LandingPage';
import Dashboard from './components/Dashboard';

import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState('');

  const handleLogin = (type) => {
    setIsLoggedIn(true);
    setUserType(type);
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={
            isLoggedIn ? <Navigate to="/dashboard" /> : <LandingPage onLogin={handleLogin} />
          } />
          <Route path="/dashboard" element={
            isLoggedIn ? <Dashboard userType={userType} /> : <Navigate to="/" />
          } />
          <Route path="/profile" element={isLoggedIn ? <Profile /> : <Navigate to="/" />} />
          <Route path="/redemptions" element={isLoggedIn ? <Redemptions /> : <Navigate to="/" />} />
          <Route path="/stores" element={isLoggedIn ? <Stores /> : <Navigate to="/" />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;