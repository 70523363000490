import React, { useState, useEffect } from 'react';
import api from '../utils/api';
import { API_URL } from '../config';

function Redemptions() {
  const [redemptions, setRedemptions] = useState([]);
  const [newRedemption, setNewRedemption] = useState({ amount: '' });

  useEffect(() => {
    const fetchRedemptions = async () => {
      try {
        const response = await api.get('/redemptions');
        setRedemptions(response.data);
      } catch (error) {
        console.error('Error fetching redemptions:', error);
        // Handle error (e.g., show error message to user)
      }
    };

    fetchRedemptions();
  }, []);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post(`${API_URL}/redemptions`, {
  //       ...newRedemption,
  //       store_id: 1, // Default store_id to 1
  //       date: new Date().toISOString()
  //     }, {
  //       headers: {'Content-Type': 'application/json'}
  //     });
      
  //     setRedemptions([...redemptions, response.data]);
  //     setNewRedemption({ amount: '' }); // Reset form
  //   } catch (error) {
  //     console.error('Error creating redemption:', error);
  //     // Handle error (e.g., show error message to user)
  //   }
  // };

  // const handleInputChange = (e) => {
  //   setNewRedemption({ ...newRedemption, [e.target.name]: e.target.value });
  // };

  return (
    <div>
      <h2>Your Redemptions</h2>
      
      {/* New redemption form */}
      {/* <form onSubmit={handleSubmit}>
        <input
          type="number"
          name="amount"
          value={newRedemption.amount}
          onChange={handleInputChange}
          placeholder="Amount"
          required
        />
        <button type="submit">Create Redemption</button>
      </form> */}
      <ul>
        {redemptions.map((redemption) => (
          <li key={redemption.id}>{/* Redemption details */}</li>
        ))}
      </ul>
    </div>
  );
}

export default Redemptions;