import React from 'react';
import { Link } from 'react-router-dom';

function Dashboard({ userType }) {

  const renderNavItems = () => {
    const items = [
      <li key="profile"><Link to="/profile">Profile</Link></li>
    ];
    if (userType === 'customer') {
      
      items.push(<li key="redemptions"><Link to="/redemptions">Redemptions</Link></li>);
    }
    if (userType === 'admin') {
      items.push(<li key="stores"><Link to="/stores">Stores</Link></li>);
    }

    return items;
  };


  return (
    <div className="dashboard">
      <h1>Welcome to Your Dashboard</h1>
      <p>User Type: {userType}</p>
      <nav>
        <ul>
          {renderNavItems()}
        </ul>
      </nav>
    </div>
  );
}

export default Dashboard;