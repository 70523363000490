import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../utils/api';

function Profile() {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const timestamp = new Date().getTime();
        const data = await api.get(`/current-user?timestamp=${timestamp}`);
        setProfile(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  return (
    <div>
      <button onClick={() => navigate('/')} className="back-button">
        Back to Main Page
      </button>
      <h2>Profile</h2>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : profile && profile.data ? (
        <>
          <pre>{JSON.stringify(profile.data.username)}</pre>
          <pre>{JSON.stringify(profile.data.user_type)}</pre>
        </>
      ) : (
        <p>No profile data available</p>
      )}

    </div>
  );

  // ... return statement remains the same ...
}

export default Profile;