import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../config';

function LandingPage({ onLogin }) {

  const [registerEmail, setRegisterEmail] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  const [registerUserType, setUserType] = useState('');
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}/register`, { username: registerEmail, password: registerPassword, user_type: registerUserType }, {headers: {'Content-Type': 'application/json'}});
      console.log('Registration successful', response.data);
      // Handle successful registration (e.g., show success message, redirect)
    } catch (error) {
      console.error('Registration failed', error);
      // Handle registration error (e.g., show error message)
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: loginEmail,
          password: loginPassword,
          timestamp: new Date().toISOString()
        })
      });
      
      if (!response.ok) {
        throw new Error('Login failed');
      }
      
      const data = await response.json();
      console.log('Login successful', data);
      const { access_token } = data;
      localStorage.setItem('access_token', access_token);
      onLogin(data.user_type);
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  return (
    <div className="landing-page">
      <h1>CuTrade</h1>
      <div className="auth-forms">
        <form onSubmit={handleRegister}>
          <h2>Register</h2>
          <input
            type="email"
            placeholder="Email"
            value={registerEmail}
            onChange={(e) => setRegisterEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={registerPassword}
            onChange={(e) => setRegisterPassword(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="type"
            onChange={(e) => setUserType(e.target.value)}
            value={registerUserType}
            required
          />
          <button type="submit">Register</button>
        </form>
        <form onSubmit={handleLogin}>
          <h2>Login</h2>
          <input
            type="email"
            placeholder="Email"
            value={loginEmail}
            onChange={(e) => setLoginEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={loginPassword}
            onChange={(e) => setLoginPassword(e.target.value)}
            required
          />
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
}

export default LandingPage;